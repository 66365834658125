<template>
    <div class="wrap">
        <div class="nav_bar">
            <van-icon name="arrow-left" size="20" style="position: absolute;left: 18px;top: 15px;"
                @click="onClickLeft" />
            <span style="font-size: 20px;font-weight: bold;">{{$route.query.title}}</span>
        </div>
        <!-- <div class="title">{{ info.reportTitle }}</div> -->
        <div class="content">{{ info.contentText }}</div>
    </div>
</template> 
<script>
import { getDetail } from "@/api/online";
export default {
    data() {
        return {
            tenantId: localStorage.getItem('tenantId'),
            info: {}
        }
    },
    mounted() {
        this.getdata()
    },
    methods: {
        getdata() {
            getDetail(
                this.tenantId,
                this.$route.query.id
            ).then((res) => {
                if (res.data.code == "200") {
                    this.info = res.data.data
                }
            });
        },
        onClickLeft() {
            this.$router.back()
            // this.$router.push({ path: '/menu' })
        },
    }
}
</script>
<style lang="less" scoped>
.wrap {
    background-color: #F6F6F6;
    // padding-top: 20px;
    height: 100vh;

    .nav_bar {
        text-align: center;
        color: #fff;
        background-color: #1578F9;
        padding: 10px 0px 50px 0px;
    }

    .title {
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
    }
    .content{
        // margin: 10px 15px;
        font-size: 16px;
        line-height: 25px;
        text-indent: 1em;
        width: 90%;
        margin: -20px auto;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
    }
}
</style>