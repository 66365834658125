import request from '@/router/axios';

// 科室门诊列表
export const getoutpatient = (tenantId,startTime,endTime) => request({
  url: '/api/blade-medicine-tools/hospitaldata/getoutpatientecharts',
  method: 'get',
  params:{
    tenantId:tenantId,
    startTime:startTime,
    endTime:endTime,
  }
})
// 出院数据
export const getouthospitale = (tenantId,startTime,endTime) => request({
  url: '/api/blade-medicine-tools/hospitaldata/getouthospitalecharts',
  method: 'get',
  params:{
    tenantId:tenantId,
    startTime:startTime,
    endTime:endTime,
  }
})
// 入院数据
export const getinhospital = (tenantId,startTime,endTime) => request({
  url: '/api/blade-medicine-tools/hospitaldata/getinhospitalecharts',
  method: 'get',
  params:{
    tenantId:tenantId,
    startTime:startTime,
    endTime:endTime,
  }
})
// 手术数据
export const getsurgicaldata = (tenantId,startTime,endTime) => request({
  url: '/api/blade-medicine-tools/hospitaldata/getsurgicaldataecharts',
  method: 'get',
  params:{
    tenantId:tenantId,
    startTime:startTime,
    endTime:endTime,
  }
})
// 手术室使用情况
export const getoperationusage = (tenantId,startTime,endTime) => request({
  url: '/api/blade-medicine-tools/hospitaldata/getoperationusage',
  method: 'get',
  params:{
    tenantId:tenantId,
    startTime:startTime,
    endTime:endTime,
  }
})
// 昨日当天门诊数量
export const getyesterdayoutpatient = (tenantId,startTime,endTime) => request({
  url: '/api/blade-medicine-tools/hospitaldata/getyesterdayoutpatientecharts',
  method: 'get',
  params:{
    tenantId:tenantId,
    startTime:startTime,
    endTime:endTime,
  }
})
// 获取在线报表列表
export const getList = (tenantId,startTime,endTime) => request({
  url: `/api/blade-medicine-tools/medicalcareonlinereport/getList`,
  method: 'get',
  params:{
    tenantId:tenantId,
    startTime:startTime,
    endTime:endTime,
  }
})
// 获取在线报表列表
export const getDetail = (tenantId,reportId) => request({
  url: '/api/blade-medicine-tools/medicalcareonlinereport/getDetail',
  method: 'get',
  params:{
    tenantId:tenantId,
    reportId:reportId,
  }
})

